import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Triangle } from '../models';
import { head } from 'ramda';

@Injectable()
export class TriangleService {
  private readonly api = '/api/patient/triangulo';

  constructor(private httpClient: HttpClient) {}

  create(body: Triangle): Observable<Triangle> {
    return this.httpClient.post<Triangle[]>(this.api, [body]).pipe(map((value) => head(value) as Triangle));
  }

  delete(uuid: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.api}/${uuid}`);
  }
}
