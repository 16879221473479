import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setGuest } from '@app/features/auth/store/auth.actions';
import { ErrorType } from '@app/shared/enums';
import { AuthService } from '@app/shared/services';

import { Store } from '@ngrx/store';
import { has, prop } from 'ramda';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  loading: boolean;

  constructor(private store: Store, private authService: AuthService) {
    this.loading = false;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(
        ({
          error,
          status
        }: {
          error: { message: string; error: unknown; type: ErrorType; instance?: string; path: string; status: number };
          status: number;
        }) => {
          if (error?.path?.endsWith('diagnostic-hypothesis') && error?.status === 409) {
            return throwError(() => 'Já existe um diagnóstico com o mesmo código');
          }

          if (error?.path?.startsWith('/api/patient/specialties') && error?.status === 409) {
            return throwError(() => 'A especialidade já existe existe');
          }

          let errorMsg =
            error && error?.message?.startsWith('Erro!')
              ? error.message
              : 'Erro interno da aplicação. Por favor, contacte o administrador do sistema';

          if (status === 401) {
            if (has('error', error) && prop('error', error) === 'invalid_grant') {
              errorMsg = error.instance?.endsWith('/auth-user/password')
                ? 'Palavra-passe antiga está incorreta'
                : 'Dados de início de sessão incorrectos';
            } else {
              errorMsg = 'A sua sessão expirou';

              const token = this.authService.getToken();

              if (token?.refresh_token && !this.loading) {
                this.loading = true;
                return this.authService.refreshToken(token.refresh_token).pipe(
                  switchMap((refreshToken) => {
                    if (!!refreshToken) {
                      this.authService.setToken(refreshToken);
                      const retryRequest = request.clone({
                        headers: request.headers.set('Authorization', `Bearer ${refreshToken.access_token}`)
                      });
                      this.loading = false;
                      return next.handle(retryRequest);
                    } else {
                      this.store.dispatch(setGuest());
                      this.loading = false;
                      return throwError(() => errorMsg);
                    }
                  }),
                  catchError(() => {
                    this.loading = false;
                    this.store.dispatch(setGuest());
                    return throwError(() => errorMsg);
                  })
                );
              } else {
                this.store.dispatch(setGuest());
              }
            }
          } else if (status === 403) {
            errorMsg = 'Você não tem permissão';
          } else if (status === 409) {
            if (error.type === ErrorType.InvalidCredentials) {
              errorMsg = 'O utilizador já existe';
            }
          } else if (status === 404 && error?.message === 'Specialty already exist') {
            errorMsg = 'Especialidade já existe';
          } else if (status === 404 && error.error) {
            errorMsg = error.error as string;
          } else if (status === 400 && error.error) {
            errorMsg = error.message || (error.error as string);
          }

          return throwError(() => errorMsg);
        }
      )
    );
  }
}
