import { Paged, Specialty, Token, User } from '@app/shared/models';
import { createAction, props } from '@ngrx/store';

export const loadProfile = createAction('[Profile] Load Profile');
export const loadProfileSuccess = createAction('[Profile] Load Profile Success', props<{ user: User }>());
export const loadProfileFailure = createAction('[Profile] Load Profile Failure', props<{ error: string }>());

export const updateProfile = createAction('[Profile] Update Profile', props<{ user: User }>());
export const updateProfileSuccess = createAction('[Profile] Update Profile Success', props<{ user: User }>());
export const updateProfileFailure = createAction('[Profile] Update Profile Failure', props<{ error: string }>());

export const updatePicture = createAction('[Profile] Update Picture', props<{ file: File }>());
export const updatePictureSuccess = createAction('[Profile] Update Picture Success', props<{ photo: string }>());
export const updatePictureFailure = createAction('[Profile] Update Picture Failure', props<{ error: string }>());

export const updatePictureStringSuccess = createAction('[Profile] Update Profile Picture Success', props<{ user: User }>());
export const updatePictureStringFailure = createAction('[Profile] Update Profile Picture Failure', props<{ error: string }>());

export const loadSpecialties = createAction('[Profile] Load Specialties');
export const loadSpecialtiesSuccess = createAction('[Profile] Load Specialties Success', props<{ specialties: Paged<Specialty> }>());
export const loadSpecialtiesFailure = createAction('[Profile] Load Specialties Failure', props<{ error: string }>());

export const searchSpecialties = createAction('[Profile] Search Specialties', props<{ search: string }>());
export const searchSpecialtiesSuccess = createAction(
  '[Profile] Search Specialties Success',
  props<{ specialties: Paged<Specialty>; search: string }>()
);
export const searchSpecialtiesFailure = createAction('[Profile] Search Specialties Failure', props<{ error: string }>());

export const loadMoreSpecialties = createAction('[Profile] Load More Specialties');
export const loadMoreSpecialtiesSuccess = createAction(
  '[Profile] Load More Specialties Success',
  props<{ specialties: Paged<Specialty> }>()
);
export const loadMoreSpecialtiesFailure = createAction('[Profile] Load More Specialties Failure', props<{ error: string }>());

export const cleanSpecialties = createAction('[Profile] Clean Specialties');

export const changePassword = createAction(
  '[Profile] Change Password',
  props<{ currentPassword: string; newPassword: string; confirmation: string }>()
);
export const changePasswordSuccess = createAction('[Profile] Change Password Success');
export const changePasswordFailure = createAction('[Profile] Change Password Failure', props<{ error: string }>());

export const changePasswordCleanUp = createAction('[Profile] Change Password Clean up');

export const refreshTokenSuccess = createAction('[Profile] Refresh Token Success', props<{ token: Token }>());
export const refreshTokenFailure = createAction('[Profile] Refresh Token Failure', props<{ error: string }>());
