<main>
  <form [formGroup]="form" (ngSubmit)="submit(form.value, form.valid)" *ngIf="{ loading: loading$ | async } as value">
    <header>
      <h2>Recuperação de palavra-passe</h2>
      <p>Insira o email associado à sua conta Medevac, para onde será enviada uma nova palavra-passe.</p>
    </header>
    <mat-form-field appearance="outline">
      <input matInput formControlName="userEmail" placeholder="Email" />
    </mat-form-field>

    <button [disabled]="form.pristine" mat-raised-button color="primary" [disabled]="value.loading">
      <ng-container *ngIf="!value.loading; else loading">Enviar</ng-container>
      <ng-template #loading>
        <mat-icon><mat-spinner color="accent" diameter="20"></mat-spinner></mat-icon>
      </ng-template>
    </button>

    <a routerLink="/..">INICIAR SESSÃO</a>
  </form>
</main>
