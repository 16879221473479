import { Pipe, PipeTransform } from '@angular/core';
import { NotificationType, Notification } from '@app/shared/models';

@Pipe({
  name: 'notifications'
})
export class NotificationsPipe implements PipeTransform {
  transform(notifications?: Notification[] | null, types?: NotificationType[]): Notification[] | undefined {
    return notifications?.filter(({ type }) => types?.includes(type));
  }
}
