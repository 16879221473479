import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { setGuest, setUser } from '@app/features/auth/store/auth.actions';
import { AuthService } from '@app/shared/services/auth.service';

import { Store } from '@ngrx/store';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private authService: AuthService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean {
    if (this.authService.getToken()) {
      return this.authService.getUserInformation()?.pipe(
        map((user) => {
          this.store.dispatch(setUser({ user }));
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
    } else {
      this.store.dispatch(setGuest());
      return false;
    }
  }
}
