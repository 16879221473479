import { Notification } from '@app/shared/models';
import { createAction, props } from '@ngrx/store';

export const loadNotifications = createAction('[Main] Load Notifications');
export const loadNotificationsSuccess = createAction('[Main] Load Notifications Success', props<{ notifications: Notification[] }>());
export const loadNotificationsFailure = createAction('[Main] Load Notifications Failure', props<{ error: string }>());

export const loadBadgeMessages = createAction('[Main] Load Badge Message');
export const loadBadgeMessagesSuccess = createAction('[Main] Load Badge Messages Success', props<{ count: number }>());
export const loadBadgeMessagesFailure = createAction('[Main] Load Badge Messages Failure', props<{ error: string }>());

export const loadBadgesNotifications = createAction('[Main] Load Badge Notifications');
export const loadBadgesNotificationsSuccess = createAction('[Main] Load Badge Notifications Success', props<{ count: number }>());
export const loadBadgesNotificationsFailure = createAction('[Main] Load Badge Notifications Failure', props<{ error: string }>());

export const saveTokenDatabase = createAction('[Main] Save Token Database', props<{ token: string }>());
export const saveTokenDatabaseSuccess = createAction('[Main] Save Token DataBase Success', props<{ token: string }>());
export const saveTokenDataBaseFailure = createAction('[Main] Save Token Database Failure', props<{ error: string }>());

export const markAllMessagesRead = createAction('[Main] Mark all Messages Read');
export const markAllMessagesReadSuccess = createAction('[Main] Mark all Message Read Success');
export const markAllMessagesReadFailure = createAction('[Main] Mark all Message Read Failure', props<{ error: string }>());

export const markAllNotificationsRead = createAction('[Main] Mark all Notifications Read');
export const markAllNotificationsReadSuccess = createAction('[Main] Mark all Notifications Read Success');
export const markAllNotificationsReadFailure = createAction('[Main] Mark all Notifications Read Failure', props<{ error: string }>());
