import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { evolve, isNil, reject } from 'ramda';
import { Observable } from 'rxjs';
import { Paged, QueryParams, ClinicalMaterial, ClinicalMaterialFilter } from '../models';
import { defaultNumber, defaultString, defaultStringArray } from '../utils';

@Injectable()
export class ClinicalMaterialService {
  readonly api = '/api/patient/clinical-material';

  constructor(private httpClient: HttpClient) {}

  getAll({ page, size, search }: QueryParams<ClinicalMaterialFilter> = {}): Observable<Paged<ClinicalMaterial>> {
    const filterDefaults = {
      page: defaultNumber,
      size: defaultNumber,
      search: defaultString,
      sort: defaultStringArray
    };

    const params = reject(
      isNil,
      evolve(filterDefaults)({
        page,
        size,
        search,
        sort: ['description']
      })
    );

    return this.httpClient.get<Paged<ClinicalMaterial>>(this.api, { params });
  }

  createClinicalMaterial(clinicalMaterial: ClinicalMaterial): Observable<ClinicalMaterial> {
    return this.httpClient.post<ClinicalMaterial>(this.api, clinicalMaterial);
  }

  updateClinicalMaterial(clinicalMaterial: ClinicalMaterial): Observable<ClinicalMaterial> {
    return this.httpClient.put<ClinicalMaterial>(`${this.api}/${clinicalMaterial.id}`, clinicalMaterial);
  }

  deleteClinicalMaterial(id: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.api}/${id}`);
  }

  updateStatus(id: string, isAvailable: boolean): Observable<ClinicalMaterial> {
    return this.httpClient.put<ClinicalMaterial>(`${this.api}/${id}`, { isAvailable });
  }
}
