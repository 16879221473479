import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    const icons = [
      'dashboard-blue',
      'evacuation-blue',
      'evacuation-white',
      'list-blue',
      'list-white',
      'chat-blue',
      'dashboard-white',
      'chat-green',
      'notification-green',
      'help-blue',
      'logout-blue',
      'chat-white',
      'user-gray',
      'user-white',
      'user-green',
      'doctor-green',
      'doctor-gray',
      'doctor-blue',
      'doctor-white',
      'email-gray',
      'email-green',
      'card-gray',
      'card-green',
      'building-gray',
      'building-green',
      'building-white',
      'phone-gray',
      'phone-green',
      'location-gray',
      'location-green',
      'notification-gray',
      'arrow-back',
      'add',
      'error',
      'done',
      'evacuation',
      'date-white',
      'date-blue',
      'date-green',
      'date-gray',
      'message',
      'time-done',
      'chart',
      'chart-selected',
      'table',
      'table-selected',
      'dropdown-arrow',
      'hand-backpack-green',
      'hand-backpack-gray',
      'hand-backpack-blue',
      'hand-backpack-white',
      'edit-gray',
      'edit-green',
      'delete',
      'procedure-blue',
      'procedure-white',
      'specialty-blue',
      'specialty-white',
      'diagnostic-hypothesis-blue',
      'diagnostic-hypothesis-white',
      'health',
      'done-outlined',
      'upload',
      'file',
      'delete-gray',
      'file-white',
      'file-gray',
      'visibility',
      'visibility-off',
      'send-report',
      'check',
      'step',
      'triangle'
    ];

    icons.forEach((name) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`));
    });
  }
}
