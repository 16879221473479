export enum MonitoringType {
  Grievances = 'queixaSintomasPrincipais',
  Medication = 'Medicacao',
  Avds = 'dependenciaAVDS',
  InjuryKinematics = 'injuryKinematics',
  SuspectedInfection = 'suspectedInfection',
  Allergies = 'alergias',
  LastMeal = 'ultimaRefeicao',
  RespiratoryPathologies = 'respiratoria',
  CardiovascularPathologies = 'cardiovascular',
  NeurologicalPathologies = 'neurologica',
  RenalUrologicalPathologies = 'renal',
  GastrointestinalPathologies = 'gastrointestinal',
  EndocrineMetabolicPathologies = 'endocrino',
  PsychiatricPathologies = 'psiquiatrica',
  HematologyOncologyPathologies = 'hemato',
  Habits = 'habitos',
  NewsScale = 'newsScale',
  SupplementalOxygen = 'supplementalOxygen',
  SystolicBloodPressure = 'systolicBloodPressure',
  StateOfConsciousness = 'stateConsciousness',
  Others = 'outras',

  EtCo2 = 'etco2',
  RespiratoryFrequency = 'frequenciaRespiratoria',
  Temperature = 'temperatura',
  UrinaryDebit = 'debitoUrinario',
  HeartRate = 'frequenciaCardiaca',
  OxygenSaturation = 'saturacoesPerifericasOxigenio',
  Glycemia = 'glicemia',
  Rass = 'rass'
}
