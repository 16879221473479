<header>
  <button mat-icon-button>
    <mat-icon (click)="dialogRef.close()" class="close">close</mat-icon>
  </button>
</header>

<body>
  <div *ngIf="showIcon">
    <mat-icon svgIcon="done"></mat-icon>
  </div>

  <h1 mat-dialog-title>{{ title }}</h1>

  <div mat-dialog-content class="color-gray200" [innerText]="text"></div>

  <div mat-dialog-actions class="actions">
    <button color="primary" mat-raised-button (click)="dialogRef.close(true)">Sim</button>
    <a *ngIf="cancelButton" (click)="dialogRef.close()">Não</a>
  </div>
</body>
