export enum RegexEnum {
  Alphanumeric = '^[a-zA-Z0-9 ]*$',
  Phone = '\\+?\\d{1,4}?[-.\\s]?\\(?\\d{1,4}\\)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}',
  PatientNumber = '^\\d{9}$',
  Passport = '^[A-Z]{2}\\d{6}$',
  CitizenCard = '^\\d{8}$',
  PassportOrCitizenCard = '(^[A-Z]{2}\\d{6}$)|(^\\d{8}$)',
  NISS = '^\\d{11}$',
  Gps = '^(-?\\d+(\\.\\d+)?),\\s*(-?\\d+(\\.\\d+)?)$'
}
