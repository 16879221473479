import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Monitoring } from '@app/features/evacuation/shared/models';
import { evolve, head, isNil, reject } from 'ramda';
import { Observable, map, of } from 'rxjs';
import { MonitoringType } from '../enums';
import {
  ChronicMedication,
  EvolucaoSintomas,
  HistoryDisease,
  HistoryDiseaseDropdown,
  Infection,
  InfectionFilter,
  Injury,
  InjuryFilter,
  Kinematic,
  LevelConsciousness,
  Medication,
  MedicationFilter,
  MonitoringFile,
  MonitoringFileFilter,
  MonitoringRequest,
  MonitoringResponse,
  NewsScaleOld,
  PAGE,
  Paged,
  QueryParams,
  Scale
} from '../models';
import { defaultNumber, defaultString, defaultStringArray } from '../utils';

@Injectable()
export class MonitoringService {
  readonly api = '/api/patient';
  readonly monitoringApi = '/api/patient/monitoring';
  readonly evacuationApi = `/api/patient/monitoring/evacuations`;

  constructor(private httpClient: HttpClient) {}

  getInjuries({ name, size, page }: QueryParams<InjuryFilter> = {}): Observable<Paged<Injury>> {
    const filterDefaults = {
      size: defaultNumber,
      page: defaultNumber,
      name: defaultString
    };

    const params = reject(isNil, evolve(filterDefaults)({ size, page, name }));

    return this.httpClient.get<Injury[]>(`${this.api}/injuries`, { params }).pipe(map((content) => ({ page: PAGE, content, last: true })));
  }

  getInfections({ name, size, page }: QueryParams<InfectionFilter> = {}): Observable<Paged<Infection>> {
    const filterDefaults = {
      size: defaultNumber,
      page: defaultNumber,
      name: defaultString
    };

    const params = reject(isNil, evolve(filterDefaults)({ size, page, name }));

    return this.httpClient
      .get<Infection[]>(`${this.api}/infections`, { params })
      .pipe(map((content) => ({ page: PAGE, content, last: true })));
  }

  getParameters(evacuationUuid: string): Observable<MonitoringType[]> {
    return this.httpClient.get<MonitoringType[]>(`${this.monitoringApi}/${evacuationUuid}/parameters`);
  }

  getAll(uuid: string, assessmentType?: MonitoringType[]): Observable<Monitoring[]> {
    const filterDefaults = {
      assessmentType: defaultStringArray,
      uuid: defaultString
    };

    const params = reject(isNil, evolve(filterDefaults)({ assessmentType, uuid }));

    return this.httpClient
      .get<MonitoringResponse[]>(`${this.monitoringApi}/clinical-evaluation`, { params })
      .pipe(map((response) => response.map((value) => ({ ...value, date: value.createdDate }))));
  }

  getAllByParameter(uuid: string, assessmentType: MonitoringType): Observable<Monitoring[]> {
    const filterDefaults = {
      assessmentType: defaultString
    };

    const params = reject(isNil, evolve(filterDefaults)({ assessmentType }));

    return this.httpClient.get<Monitoring[]>(`${this.monitoringApi}/tab-monitorizacoes/evacuations/${uuid}`, { params });
  }

  getMedications({ name, size, page }: QueryParams<MedicationFilter> = {}): Observable<Paged<Medication>> {
    const filterDefaults = {
      size: defaultNumber,
      page: defaultNumber,
      name: defaultString
    };

    const params = reject(isNil, evolve(filterDefaults)({ size, page, name }));
    return this.httpClient.get<Paged<Medication>>(`${this.api}/medications`, { params });
  }

  createClinicalEvaluation({ value, parameter: monitoringType }: Monitoring, evacuationUuid: string): Observable<Monitoring> {
    if (monitoringType === MonitoringType.Medication) {
      return this.createChronicMedication(value as number, evacuationUuid).pipe(
        map(({ createdDate, medicationRepresentation }) => ({
          value: medicationRepresentation.nomeCient,
          parameter: monitoringType,
          date: createdDate
        }))
      );
    } else if (
      [
        MonitoringType.Avds,
        MonitoringType.InjuryKinematics,
        MonitoringType.LastMeal,
        MonitoringType.SuspectedInfection,
        MonitoringType.Allergies,
        MonitoringType.Grievances
      ].includes(monitoringType!)
    ) {
      return of({});
    } else if (
      [
        MonitoringType.Habits,
        MonitoringType.RespiratoryPathologies,
        MonitoringType.CardiovascularPathologies,
        MonitoringType.NeurologicalPathologies,
        MonitoringType.RenalUrologicalPathologies,
        MonitoringType.GastrointestinalPathologies,
        MonitoringType.EndocrineMetabolicPathologies,
        MonitoringType.PsychiatricPathologies,
        MonitoringType.HematologyOncologyPathologies,
        MonitoringType.Others
      ].includes(monitoringType!)
    ) {
      return this.createHistoryDisease(monitoringType!, value as string, evacuationUuid);
    } else {
      return of();
    }
  }

  createChronicMedication(medicationUuid: number, evacuationUuid: string): Observable<ChronicMedication> {
    return this.httpClient
      .post<ChronicMedication[]>(`${this.monitoringApi}/chronic-medication`, [{ evacuationUuid, medicationUuid }])
      .pipe(map((value) => head(value)!));
  }

  createEvolucaoSintomas(body: EvolucaoSintomas, evacuationUuid: string): Observable<EvolucaoSintomas> {
    return this.httpClient.post<EvolucaoSintomas>(`${this.monitoringApi}/evolucaoSintomas`, [{ ...body, evacuationUuid }]);
  }

  createLevelConsciousness(body: LevelConsciousness, evacuationUuid: string): Observable<LevelConsciousness> {
    return this.httpClient.post<LevelConsciousness>(`${this.monitoringApi}/levelConsciousness`, [{ ...body, evacuationUuid }]);
  }

  mapNewScale(scale: NewsScaleOld): NewsScaleOld {
    return {
      warning: scale.warning,
      value: scale.value,
      evacuationUuid: scale.evacuationUuid,
      createdDate: scale.createdDate,
      respirationRate: scale.respirationRate,
      oxygenSaturation: scale.oxygenSaturation,
      temperature: scale.temperature,
      systolicBloodPressure: scale.systolicBloodPressure,
      heartRate: scale.heartRate,
      stateConsciousness: scale.stateConsciousness,
      supplementalOxygen: scale.supplementalOxygen
    };
  }

  createNewsScale(value: NewsScaleOld, evacuationUuid: string): Observable<NewsScaleOld> {
    return this.httpClient
      .post<NewsScaleOld[]>(`${this.monitoringApi}/news-scale/v2`, [{ ...value, evacuationUuid }])
      .pipe(map((value) => this.mapNewScale(head(value)!)));
  }

  getNewsScale(id: string): Observable<NewsScaleOld[]> {
    return this.httpClient
      .get<NewsScaleOld[]>(`${this.monitoringApi}/news-scale/v2/${id}`)
      .pipe(map((newsScale) => newsScale.map((scale) => this.mapNewScale(scale))));
  }

  createMonitoring(
    value: string | boolean | number | undefined,
    evacuationUuid: string,
    monitoringType: MonitoringType
  ): Observable<Monitoring> {
    return this.httpClient
      .post<MonitoringRequest[]>(`${this.monitoringApi}`, [
        {
          evacuationUuid,
          parametro: monitoringType.toString(),
          valorRegistadoDoParametro: value
        }
      ])
      .pipe(
        map((response) => head(response)!),
        map((response) => ({
          date: response.createdDate,
          value: response.parametro.ValorRegistado,
          parameter: response.parametro.parametro
        }))
      );
  }

  historyDiseaseList(pathologie: MonitoringType): Observable<HistoryDiseaseDropdown[]> {
    return this.httpClient.get<HistoryDiseaseDropdown[]>(`${this.monitoringApi}/history-disease-dropdown`, { params: { pathologie } });
  }

  createHistoryDisease(type: MonitoringType, value: string, evacuationUuid: string): Observable<Monitoring> {
    return this.httpClient.post<HistoryDisease[]>(`${this.monitoringApi}/history-disease`, [{ [type]: value, evacuationUuid }]).pipe(
      map((value) => head(value)),
      map((response) => {
        return {
          value: response ? (response![type] as string) : '',
          date: response?.createdDate,
          parameter: type
        };
      })
    );
  }

  getFiles(evacuationUuid: string, { page, size, search }: QueryParams<MonitoringFileFilter> = {}): Observable<Paged<MonitoringFile>> {
    const filterDefaults = {
      size: defaultNumber,
      page: defaultNumber,
      search: defaultString
    };

    const params = reject(isNil, evolve(filterDefaults)({ size, page, search }));

    return this.httpClient.get<Paged<MonitoringFile>>(`${this.monitoringApi}/listFiles`, {
      params: { ...params, evacuationUuid }
    });
  }

  getFile(id: string): Observable<Blob> {
    return this.httpClient.get<Blob>(`${this.monitoringApi}/view-file`, { params: { uuidAttachment: id }, responseType: 'blob' as 'json' });
  }

  addFile(evacuationUuid: string, file: File): Observable<MonitoringFile> {
    const formData = new FormData();

    formData.append('file', file);

    return this.httpClient.post<MonitoringFile>(`${this.monitoringApi}/uploadFile`, formData, { params: { evacuationUuid } });
  }

  deleteFile(uuid: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.monitoringApi}/listFiles/${uuid}`);
  }

  createMedication(medications: string, uuid: string, parameter: MonitoringType): Observable<Monitoring> {
    return this.httpClient
      .post<Monitoring>(`${this.monitoringApi}/medications/`, { evacuationUuid: uuid, medications })
      .pipe(map((value) => ({ ...value, value: medications, id: uuid, parameter })));
  }

  getNewsScaleScore(id: string): Observable<Scale> {
    return this.httpClient.get<Scale>(`${this.monitoringApi}/news-scale/${id}`);
  }
}
