import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { evolve, isNil, reject } from 'ramda';
import { Observable } from 'rxjs';
import { Paged, QueryParams, Specialty, SpecialtyFilter } from '../models';
import { defaultNumber, defaultString, defaultStringArray } from '../utils';

@Injectable()
export class SpecialtyService {
  private readonly api = '/api/patient/specialties';

  constructor(private httpClient: HttpClient) {}

  getAll({ page, size, search }: QueryParams<SpecialtyFilter> = {}): Observable<Paged<Specialty>> {
    const filterDefaults = {
      page: defaultNumber,
      size: defaultNumber,
      search: defaultString,
      sort: defaultStringArray
    };

    const params = reject(isNil, evolve(filterDefaults)({ page, size, search, sort: ['specialty'] }));

    return this.httpClient.get<Paged<Specialty>>(this.api, { params });
  }

  createSpecialty(specialty: Specialty): Observable<Specialty> {
    return this.httpClient.post<Specialty>(this.api, specialty);
  }

  updateSpecialty(specialty: Specialty): Observable<Specialty> {
    return this.httpClient.put<Specialty>(`${this.api}/${specialty.id}`, specialty);
  }

  deleteSpecialty(id: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.api}/${id}`);
  }

  updateStatus(id: string, isAvailable: boolean): Observable<Specialty> {
    return this.httpClient.put<Specialty>(`${this.api}/${id}`, { isAvailable });
  }
}
