export enum CurrentPregnancyType {
  LastMenstruation = 'dataUltimaMenstruacao',
  ProbableDate = 'dataProvavelParto',
  GestationAge = 'idadeGestacional',
  BloodGroup = 'grupoSanguineo',
  Cigarette = 'fumo',
  Alcohol = 'alcool',
  Drugs = 'drogas',
  Intercurrences = 'intercorrencias'
}
