import { Token, User } from '@app/shared/models';
import { createAction, props } from '@ngrx/store';

export const login = createAction('[Auth] Login', props<{ username: string; password: string }>());
export const loginSuccess = createAction('[Auth] Login Success', props<{ token: Token; user: User }>());
export const loginFailure = createAction('[Auth] Login Failure', props<{ error: string }>());

export const firebaseLogin = createAction('[Auth] Firebase Login');
export const firebaseLoginSuccess = createAction('[Auth] Firebase Login Success');
export const firebaseLoginFailure = createAction('[Auth] Firebase Login Failure', props<{ error: string }>());

export const setGuest = createAction('[Auth] Set Guest');

export const setUser = createAction('[Auth] Set User', props<{ user: User }>());

export const saveUserDatabaseSuccess = createAction('[Auth] Save User Database Success');
export const saveUserDatabaseFailure = createAction('[Auth] Save User Database Failure', props<{ error: string }>());

export const loadUserInformationSuccess = createAction('[Auth] Load User Information Success', props<{ user: User }>());
export const loadUserInformationFailure = createAction('[Auth] Load User Information Failure', props<{ error: string }>());

export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout Success', props<{ userId: string }>());
export const logoutFailure = createAction('[Auth] Logout Failure', props<{ error: string }>());

export const recoveryPassword = createAction('[Auth] Recovery Password', props<{ userEmail: string }>());
export const recoveryPasswordSuccess = createAction('[Auth] Recovery Password Success');
export const recoveryPasswordFailure = createAction('[Auth] Recovery Password Failure', props<{ error: string }>());

export const cleanRecoveryPassword = createAction('[Auth] Recovery password cleanUP');

export const changePassword = createAction('[Auth] Change Password', props<{ newPassword: string; confirmation: string; token: string }>());
export const changePasswordSuccess = createAction('[Auth] Change Password Success');
export const changePasswordFailure = createAction('[Auth] Change Password Failure', props<{ error: string }>());

export const cleanChangePassword = createAction('[Auth] Change Password CleanUp');

export const removeFcmTokenSuccess = createAction('[Auth] Remove FCM Token Success');
export const removeFcmTokenFailure = createAction('[Auth] Remove FCM Token Failure', props<{ error: string }>());
