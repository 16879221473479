import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { UserService } from '@app/shared/services/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import { Store, select } from '@ngrx/store';
import { selectCurrentUser } from './auth.selectors';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private snackBar: MatSnackBar,
    private router: Router,
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private authService: AuthService
  ) {}

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(({ username, password }) =>
        this.authService.login(username, password).pipe(
          map(({ token, user }) => AuthActions.loginSuccess({ token, user })),
          catchError((error: string) => of(AuthActions.loginFailure({ error })))
        )
      )
    )
  );

  loadInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setUser),
      switchMap(() =>
        this.authService.getUserInformation().pipe(
          map((user) => AuthActions.loadUserInformationSuccess({ user })),
          catchError((error: string) => of(AuthActions.loadUserInformationFailure({ error })))
        )
      )
    )
  );

  saveUserDatabase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUserInformationSuccess),
      switchMap(({ user }) =>
        from(this.userService.saveUserDatabase(user)).pipe(
          map(() => AuthActions.saveUserDatabaseSuccess()),
          catchError((error) => of(AuthActions.saveUserDatabaseFailure({ error })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      withLatestFrom(this.store.pipe(select(selectCurrentUser))),
      filter(([, user]) => !!user),
      switchMap(([, user]) => {
        const token = this.authService.getToken();
        return (token ? this.authService.logout(token?.refresh_token) : of(true)).pipe(
          map(() => AuthActions.logoutSuccess({ userId: user!.uuid! })),
          catchError((error) => of(AuthActions.logoutFailure({ error })))
        );
      })
    )
  );

  removeFcmToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout, AuthActions.setGuest),
      withLatestFrom(this.store.pipe(select(selectCurrentUser))),
      filter(([, user]) => !!user && !!this.authService.getFcmToken()),
      switchMap(([, user]) => {
        const token = this.authService.getFcmToken();
        return from(this.userService.removeFcmToken(user!.uuid!, token!)).pipe(
          map(() => AuthActions.removeFcmTokenSuccess()),
          catchError((error) => of(AuthActions.removeFcmTokenFailure({ error })))
        );
      })
    )
  );

  recoveryPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.recoveryPassword),
      switchMap(({ userEmail }) =>
        this.authService.recoveryPassword(userEmail).pipe(
          map(() => AuthActions.recoveryPasswordSuccess()),
          catchError((error) => of(AuthActions.loadUserInformationFailure({ error })))
        )
      )
    )
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePassword),
      switchMap(({ newPassword, confirmation, token }) =>
        this.authService.changePasswordByToken(newPassword, confirmation, token).pipe(
          map(() => AuthActions.changePasswordSuccess()),
          catchError((error) => of(AuthActions.changePasswordFailure({ error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(({ token }) => {
          this.authService.setToken(token);
          void this.router.navigateByUrl('/');
        })
      ),
    { dispatch: false }
  );

  logoutEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout, AuthActions.setGuest),
        tap(() => {
          localStorage.removeItem('token');
          void this.router.navigate(['credentials']);
          this.authService.removeFcmToken();
        })
      ),
    { dispatch: false }
  );

  loginFirebase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      switchMap(() =>
        this.authService.getFirebaseToken().pipe(
          switchMap((token) => {
            return defer(() => from(this.afAuth.signInWithCustomToken(token))).pipe(
              map(() => AuthActions.firebaseLoginSuccess()),
              catchError((error) => of(AuthActions.firebaseLoginFailure({ error })))
            );
          })
        )
      )
    )
  );

  failures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthActions.loginFailure,
          AuthActions.loadUserInformationFailure,
          AuthActions.saveUserDatabaseFailure,
          AuthActions.firebaseLoginFailure,
          AuthActions.recoveryPasswordFailure,
          AuthActions.changePasswordFailure
        ),
        tap(({ error }) => {
          this.snackBar.open(error, undefined, { duration: 4000 });
        })
      ),
    { dispatch: false }
  );
}
