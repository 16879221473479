const server = 'https://dev-medevac.tech.augentic.com';

export const environment = {
  production: false,
  serverUrl: `${server}/api`,
  patientUrl: `${server}/patient`,
  firebaseConfig: {
    apiKey: 'AIzaSyAMJ4CFgcAAivbV13C4zO92v7oOC3b9Psw',
    authDomain: 'medevac-hseit.firebaseapp.com',
    projectId: 'medevac-hseit',
    storageBucket: 'medevac-hseit.appspot.com',
    messagingSenderId: '610072405500',
    appId: '1:610072405500:web:44e760ce1592910300be79',
    measurementId: 'G-NS6RZDHMJG'
  },
  emulator: false
};
