import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScaleSummary } from '../models';

@Injectable()
export class ScaleService {
  private readonly api = '/api/patient/scale';

  constructor(private httpClient: HttpClient) {}

  get(): Observable<Blob> {
    return this.httpClient.get<Blob>(`${this.api}/file`, { responseType: 'blob' as 'json' });
  }

  upload(file: File): Observable<boolean> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post<boolean>(`${this.api}/upload-file`, formData);
  }

  summary(): Observable<ScaleSummary> {
    return this.httpClient.get<ScaleSummary>(`${this.api}/summary-file`);
  }
}
