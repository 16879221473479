import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from '@app/shared/components/confirmation-modal/confirmation-modal.component';
import { passwordMatchValidator } from '@app/shared/validations';
import { Store, select } from '@ngrx/store';
import { append } from 'ramda';
import { Observable, Subscription, filter } from 'rxjs';
import { changePassword, cleanChangePassword } from '../../store/auth.actions';
import { selectPasswordChangeLoading, selectPasswordChanged } from '../../store/auth.selectors';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnDestroy {
  form: FormGroup;

  loading$: Observable<boolean | undefined>;

  token: string;

  subscriptions: Subscription[];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.loading$ = this.store.pipe(select(selectPasswordChangeLoading));

    this.form = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmation: ['', Validators.required]
    });

    this.form.setValidators(passwordMatchValidator());

    this.token = this.route.snapshot.queryParams['token'];

    this.subscriptions = [];

    const sub = this.store
      .pipe(
        select(selectPasswordChanged),
        filter((value) => !!value)
      )
      .subscribe(() => this.confirmation());

    this.subscriptions = append(sub, this.subscriptions);
  }

  submit({ newPassword, confirmation }: { newPassword: string; confirmation: string }, valid: boolean, token: string): void {
    if (valid) {
      if (token) {
        this.store.dispatch(changePassword({ newPassword, confirmation, token }));
      } else {
        this.snackBar.open('O token não é válido', undefined, { duration: 4000 });
      }
    }
  }

  confirmation(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '25.438rem',
      autoFocus: false,
      data: {
        title: 'Palavra-passe',
        text: `A a sua palavra-passe foi alterada.`,
        cancelButton: false,
        showIcon: true
      }
    });

    const sub = dialogRef
      .afterClosed()
      .pipe(filter((value) => !!value))
      .subscribe(() => {
        this.router.navigate(['../']);
      });

    this.subscriptions = append(sub, this.subscriptions);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.store.dispatch(cleanChangePassword());
  }
}
