import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, map, Observable } from 'rxjs';
import { Notification, NotificationType } from '../models';

@Injectable()
export class NotificationService {
  readonly notificationsCollection = 'notifications';

  constructor(private afs: AngularFirestore) {}

  getMessages(userId: string): Observable<Notification[]> {
    return this.afs
      .collection<Notification>(this.notificationsCollection, (ref) => ref.where('userId', '==', userId).orderBy('date', 'desc'))
      .snapshotChanges()
      .pipe(
        map((docs) =>
          docs.map((doc) => ({
            id: doc.payload.doc.id,
            ...doc.payload.doc.data()
          }))
        )
      );
  }

  countMessageNotRead(userId: string): Observable<number> {
    return this.afs
      .collection<Notification>(this.notificationsCollection, (ref) =>
        ref.where('userId', '==', userId).where('read', '==', false).where('type', '==', NotificationType.Chat)
      )
      .snapshotChanges()
      .pipe(map((values) => values.length));
  }

  countNotificationsNotRead(userId: string): Observable<number> {
    return this.afs
      .collection<Notification>(this.notificationsCollection, (ref) =>
        ref.where('userId', '==', userId).where('read', '==', false).where('type', '!=', NotificationType.Chat)
      )
      .snapshotChanges()
      .pipe(map((values) => values.length));
  }

  markAllMessageIsRead(ids: string[]): Observable<boolean> {
    const batch = this.afs.firestore.batch();

    const collection = this.afs.collection(this.notificationsCollection).ref;

    ids.forEach((id) => {
      batch.update(collection.doc(id), { read: true });
    });

    return from(batch.commit()).pipe(map(() => true));
  }
}
