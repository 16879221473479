import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { evolve, isNil, omit, reject } from 'ramda';
import { Observable } from 'rxjs';
import { Group, GroupFilter, Paged, QueryParams } from '../models';
import { defaultNumber, defaultString } from '../utils';

@Injectable()
export class GroupService {
  readonly api = '/api/auth-user/groups';

  constructor(private httpClient: HttpClient) {}

  all({ name, size, page }: QueryParams<GroupFilter> = {}): Observable<Paged<Group>> {
    const filterDefaults = {
      size: defaultNumber,
      page: defaultNumber,
      name: defaultString
    };

    const params = reject(isNil, evolve(filterDefaults)({ size, page, name }));

    return this.httpClient.get<Paged<Group>>(this.api, { params });
  }

  getGroup(id: string): Observable<Group> {
    return this.httpClient.get<Group>(`${this.api}/${id}`);
  }

  getSubGroups(id: string): Observable<Group[]> {
    return this.httpClient.get<Group[]>(`${this.api}/${id}/subgroups`);
  }

  create(group: Group): Observable<Group> {
    return this.httpClient.post<Group>(this.api, omit(['uuid'], group));
  }

  update(group: Group): Observable<Group> {
    return this.httpClient.patch<Group>(`${this.api}/${group.uuid}`, omit(['uuid'], group));
  }

  createSubGroup(groupId: string, subGroup: Group): Observable<Group> {
    return this.httpClient.post<Group>(`${this.api}/${groupId}/subgroups`, omit(['uuid'], subGroup));
  }

  getGroupsByUser(uuid: string): Observable<Group[]> {
    return this.httpClient.get<Group[]>(`${this.api}/user/${uuid}/groups`);
  }

  remove(uuid: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.api}/${uuid}`);
  }

  getGroups({ name, size, page }: QueryParams<GroupFilter> = {}): Observable<Paged<Group>> {
    const filterDefaults = {
      size: defaultNumber,
      page: defaultNumber,
      name: defaultString
    };

    const params = reject(isNil, evolve(filterDefaults)({ size, page, name }));

    return this.httpClient.get<Paged<Group>>(`${this.api}/users`, { params });
  }
}
