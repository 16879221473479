import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Observable, map } from 'rxjs';
import { Token, User } from '../models';

@Injectable()
export class AuthService {
  readonly api = `/api/auth-user`;

  constructor(private httpClient: HttpClient) {}

  login(username: string, password: string): Observable<{ token: Token; user: User }> {
    return this.httpClient
      .post<Token>(`${this.api}/login`, {
        username,
        password
      })
      .pipe(
        map(({ access_token, refresh_token }) => {
          const user = jwt_decode(access_token) as User;
          return { token: { access_token, refresh_token }, user };
        })
      );
  }

  setToken(token: Token): void {
    localStorage.setItem('token', JSON.stringify(token));
  }

  setFcmToken(token: string): void {
    localStorage.setItem('fcm-token', token);
  }

  removeFcmToken(): void {
    localStorage.removeItem('fcm-token');
  }

  getFcmToken(): string | null {
    return localStorage.getItem('fcm-token');
  }

  getToken(): Token | null {
    const token = localStorage.getItem('token');

    if (!!token) {
      try {
        const parsedToken = JSON.parse(token);
        return parsedToken;
      } catch {
        return null;
      }
    } else {
      return null;
    }
  }

  getUserInformation(): Observable<User> {
    return this.httpClient.get<User>(`${this.api}/users/userinfo`);
  }

  refreshToken(refreshToken: string): Observable<Token> {
    return this.httpClient.post<Token>(`${this.api}/login/refresh-token`, { refreshToken });
  }

  getFirebaseToken(): Observable<string> {
    return this.httpClient.get<{ token: string }>(`${this.api}/login/chat-login`).pipe(map(({ token }) => token));
  }

  recoveryPassword(userEmail: string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.api}/password/forgot`, { userEmail });
  }

  changePasswordByToken(newPassword: string, confirmation: string, token: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.api}/password/token/${token}`, { newPassword, confirmation });
  }

  changePassword(currentPassword: string, newPassword: string, confirmation: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.api}/password`, { currentPassword, newPassword, confirmation });
  }

  logout(refreshToken: string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.api}/login/logout`, { refreshToken });
  }
}
