import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, map, Observable } from 'rxjs';
import { Chat, Message, User } from '../models';

import { arrayUnion, Timestamp } from '@angular/fire/firestore';
import { DateTime } from 'luxon';

@Injectable()
export class ChatService {
  constructor(private afs: AngularFirestore) {}

  getChats(userId: string): Observable<Chat[]> {
    return this.afs
      .collection<Chat>('chats', (ref) => ref.where('users', 'array-contains', userId).orderBy('updatedAt', 'desc'))
      .snapshotChanges()
      .pipe(
        map((docs) =>
          docs.map((doc) => ({
            id: doc.payload.doc.id,
            ...doc.payload.doc.data()
          }))
        )
      );
  }

  getChat(chatId: string): Observable<Chat> {
    return this.afs
      .collection<any>('chats')
      .doc(chatId)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          return { id: doc.payload.id, ...doc.payload.data() };
        })
      );
  }

  sendMessage(chatId: string, text: string, userId: string, to: string): Promise<void> {
    const ref = this.afs.collection('chats').doc(chatId);

    return ref.update({
      lastMessage: text,
      new: to,
      updatedAt: Timestamp.now().toDate(),
      messages: arrayUnion({
        text,
        date: Timestamp.now().toDate(),
        user: userId
      })
    });
  }

  createChat(to: string, userId: string, text: string): Observable<string> {
    const ref = this.afs.collection('chats');

    return from(
      ref
        .add({
          users: [to, userId],
          lastMessage: text,
          new: to,
          updatedAt: DateTime.now().toISO(),
          messages: arrayUnion({
            text,
            date: Timestamp.now().toDate(),
            user: userId
          })
        })
        .then((value) => value.id)
    );
  }

  messageRead(chatId: string): Observable<boolean> {
    const ref = this.afs.collection('chats').doc(chatId);

    return from(ref.update({ new: '' }).then(() => true));
  }

  getGeneral(): Observable<Message[]> {
    return this.afs
      .collection<Message>('generalChat', (ref) => ref.orderBy('date', 'asc'))
      .snapshotChanges()
      .pipe(map((docs) => docs.map((doc) => doc.payload.doc.data())));
  }

  sendGeneralMessage(user: User, text: string): Observable<string> {
    const ref = this.afs.collection('generalChat');

    return from(
      ref
        .add({
          text,
          date: Timestamp.now().toDate(),
          userId: user.uuid,
          user: {
            name: user.name,
            specialty: user.specialty,
            photo: user.photo
          }
        })
        .then((value) => value.id)
    );
  }
}
