import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvacuationStep } from '../models';

@Injectable()
export class EvacuationStepService {
  private readonly api = '/api/patient/evacuations';

  constructor(private httpClient: HttpClient) {}

  get(evacuationId: string): Observable<EvacuationStep> {
    return this.httpClient.get<EvacuationStep>(`${this.api}/${evacuationId}/steps`);
  }
}
