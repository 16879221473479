import { Timestamp } from 'firebase/firestore';

export interface Notification {
  id?: string;
  title: string;
  body: string;
  image: string;
  date: Timestamp;
  read: boolean;
  type: NotificationType;
  evacuationId?: string;
}

export enum NotificationType {
  Message = 'Message',
  Evacuation = 'Evacuation',
  EvacuationChat = 'EvacuationChat',
  Chat = 'Chat'
}
