import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '@env/environment';
import { DateTime } from 'luxon';
import { evolve, isNil, omit, reject } from 'ramda';
import { Observable, from, map, of } from 'rxjs';
import { EvacuationStatus } from '../enums';
import {
  ApproveEvacuationRequest,
  Evacuation,
  EvacuationCounter,
  EvacuationFilter,
  EvacuationRejection,
  Message,
  Paged,
  Params,
  Patient,
  QueryParams,
  RejectEvacuation,
  User
} from '../models';
import { defaultNumber, defaultString, defaultStringArray } from '../utils';
import { Timestamp } from 'firebase/firestore';

@Injectable()
export class EvacuationService {
  readonly collection = 'evacuations';
  readonly api = '/api/patient/evacuations';
  readonly patientApi = `${environment.serverUrl}/portal/patient`;

  constructor(private afs: AngularFirestore, private httpClient: HttpClient) {}

  getAll({ search, page, size, state, dateStart, dateEnd, sort }: QueryParams<EvacuationFilter> = {}): Observable<Paged<Evacuation>> {
    const filterDefaults = {
      first: defaultNumber,
      max: defaultNumber,
      search: defaultString,
      dateStart: defaultString,
      dateEnd: defaultString,
      sort: defaultStringArray
    };

    const params = reject(
      isNil,
      evolve(filterDefaults)({
        page,
        size,
        search,
        state,
        dateStart: (dateStart instanceof DateTime ? dateStart.toISODate() : dateStart) as string,
        dateEnd: (dateEnd instanceof DateTime ? dateEnd.toISODate() : dateEnd) as string,
        sort
      })
    );

    return this.httpClient.get<Paged<Evacuation>>(this.api, { params });
  }

  getById(id: string): Observable<Evacuation> {
    return this.httpClient.get<Evacuation>(`${this.api}/${id}`);
  }

  getOnApproval({ size, page }: Params): Observable<Evacuation[]> {
    const filterDefaults = {
      page: defaultNumber,
      size: defaultNumber
    };

    const params = reject(isNil, evolve(filterDefaults)({ page, size }));

    return this.httpClient.get<Evacuation[]>(`${this.api}/on-approval`, { params });
  }

  create(evacuation: Evacuation): Observable<Evacuation> {
    return this.httpClient.post<Evacuation>(this.api, {
      uuidDoctorOrigin: evacuation.uuidDoctorOrigin,
      uuidLocalOrigin: evacuation.uuidLocalOrigin,
      type: evacuation.type
    });
  }

  setPatient(patient: Patient, evacuationId: string): Observable<Evacuation> {
    return this.httpClient.put<Evacuation>(`${this.api}/${evacuationId}/patients`, {
      ...patient,
      birthday:
        patient.birthday instanceof DateTime ? patient.birthday.toISODate() : DateTime.fromISO(patient.birthday as string).toISODate()
    });
  }

  approve(approve: ApproveEvacuationRequest, evacuationId: string): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${this.api}/${evacuationId}/state`,
      omit(['localDestination'], {
        ...approve,
        state: EvacuationStatus.Approved,
        uuidLocalDestination: approve.localDestination?.uuid,
        ueadoctorId: approve.teamDoctor?.uuid,
        ueanurseId: approve.ueanurse?.uuid,
        additionalElements: approve.additionalElements.map(({ uuid }) => uuid)
      })
    );
  }

  sendMessage(user: User, text: string, evacuationId: string): Observable<string> {
    const ref = this.afs.collection('evacuationChat');

    return from(
      ref
        .add({
          text,
          date: Timestamp.now().toDate(),
          userId: user.uuid,
          evacuationId,
          user: {
            name: user.name,
            specialty: user.specialty,
            photo: user.photo
          }
        })
        .then((value) => value.id)
    );
  }

  getMessages(evacuationId: string): Observable<Message[]> {
    return this.afs
      .collection<Message>('evacuationChat', (ref) => ref.where('evacuationId', '==', evacuationId).orderBy('date', 'asc'))
      .snapshotChanges()
      .pipe(map((docs) => docs.map((doc) => doc.payload.doc.data())));
  }

  submit(uuid: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.api}/${uuid}/submit`, {});
  }

  counter(): Observable<EvacuationCounter> {
    return this.httpClient.get<EvacuationCounter>(`${this.api}/counter`);
  }

  summary(id: string): Observable<Evacuation> {
    return this.httpClient.get<Evacuation>(`${this.api}/${id}/summary`);
  }

  rejectEvacuation(id: string, rejectEvacuation: RejectEvacuation): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.api}/${id}/state`, { ...rejectEvacuation, state: rejectEvacuation.status });
  }

  confirm(id: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.api}/${id}/confirm`, {});
  }

  addReason(evacuationId: string, reason: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.api}/rejections/${evacuationId}/doctor-reason`, { reason }, { params: { evacuationId } });
  }

  confirmReason(evacuationId: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.api}/rejections/${evacuationId}/doctor-reason`, {}, { params: { evacuationId } });
  }

  mine(size: number): Observable<Evacuation[]> {
    return this.httpClient
      .get<Paged<Evacuation>>(`${this.api}/mine`, { params: { size, page: 0 } })
      .pipe(map(({ content }) => content || []));
  }

  getRejectionsByEvacuation(evacuationId: string): Observable<EvacuationRejection[]> {
    return this.httpClient.get<EvacuationRejection[]>(`${this.api}/${evacuationId}/rejections`);
  }

  addRejection(evacuationId: string, body: EvacuationRejection): Observable<EvacuationRejection> {
    return this.httpClient.put<EvacuationRejection>(`${this.api}/${evacuationId}/rejections`, body);
  }

  getRejections({ page, size }: Params): Observable<Paged<Evacuation>> {
    const filterDefaults = {
      page: defaultNumber,
      size: defaultNumber
    };

    const params = reject(isNil, evolve(filterDefaults)({ page, size }));

    return this.httpClient.get<Paged<Evacuation>>(`${this.api}/rejections/reason-is-null`, { params });
  }

  getRejection(evacuationId: string): Observable<EvacuationRejection> {
    return this.httpClient.get<EvacuationRejection>(`${this.api}/rejections/reason/${evacuationId}`, { params: { evacuationId } });
  }

  sendEvacuationReport(evacuationId: string, emailRecipients: string[]): Observable<boolean> {
    return this.httpClient.post<boolean>('/api/patient/report/allMonitorings', undefined, { params: { evacuationId, emailRecipients } });
  }
}
