<ng-container
  *ngIf="{
    menuOpen: menuOpen$ | async,
    user: user$ | async,
    notifications: notifications$ | async,
    badgeMessages: badgeMessages$ | async,
    badgeNotifications: badgeNotifications$ | async,
    notificationOpen: notificationsOpen$ | async,
    messagesOpen: messagesOpen$ | async
  } as value"
>
  <mat-sidenav-container (backdropClick)="closeNotifications(value.notificationOpen)">
    <mat-sidenav class="menu" #drawer [opened]="value.menuOpen" mode="side">
      <h1 class="color-primary">
        <span>MED</span>
        <span>EVAC</span>
      </h1>
      <a class="user border-blue" routerLink="/profile" href="#">
        <img class="border-primary" [src]="value.user?.photo ? value.user?.photo : 'assets/images/avatar.jpeg'" />
        <section class="info">
          <span class="color-primary">{{ value.user?.name }}</span>
          <span class="color-gray200">{{ value.user?.specialty }}</span>
        </section>
      </a>

      <mat-nav-list class="menu-main">
        <a mat-list-item routerLink="/dashboard" [class.selected-sidenav-item]="isActive('/dashboard')" href="#">
          <mat-icon [svgIcon]="isActive('/dashboard') ? 'dashboard-white' : 'dashboard-blue'"></mat-icon>
          <span>Dashboard</span>
        </a>

        <a
          mat-list-item
          routerLink="/evacuation/new"
          [class.selected-sidenav-item]="isActive('/evacuation/new')"
          href="#"
          *ngIf="[role.DoctorOrigin] | permission : value.user?.designation"
        >
          <mat-icon [svgIcon]="isActive('/evacuation/new') ? 'evacuation-white' : 'evacuation-blue'"></mat-icon>
          <span>Pedido de Evacuação</span>
        </a>
        <a mat-list-item routerLink="/evacuation" [class.selected-sidenav-item]="isActive('/evacuation')" href="#">
          <mat-icon [svgIcon]="isActive('/evacuation') ? 'list-white' : 'list-blue'"></mat-icon>
          <span>Lista de Evacuações</span>
        </a>

        <a
          mat-list-item
          *ngIf="[role.ClinicalDirection, role.ApplicationManager, role.DoctorDecision] | permission : value.user?.designation"
          routerLink="/clinical-material"
          [class.selected-sidenav-item]="isActive('/clinical-material')"
          href="#"
        >
          <mat-icon [svgIcon]="isActive('/clinical-material') ? 'hand-backpack-white' : 'hand-backpack-blue'"></mat-icon>
          <span>Material Clínico</span>
        </a>

        <a
          mat-list-item
          *ngIf="[role.ClinicalDirection, role.ApplicationManager, role.DoctorDecision] | permission : value.user?.designation"
          routerLink="/specialties"
          [class.selected-sidenav-item]="isActive('/specialties')"
          href="#"
        >
          <mat-icon [svgIcon]="isActive('/specialties') ? 'specialty-white' : 'specialty-blue'"></mat-icon>
          <span>Especialidades</span>
        </a>

        <a
          mat-list-item
          *ngIf="[role.ClinicalDirection, role.ApplicationManager, role.DoctorDecision] | permission : value.user?.designation"
          routerLink="/scale"
          [class.selected-sidenav-item]="isActive('/scale')"
          href="#"
        >
          <mat-icon [svgIcon]="isActive('/scale') ? 'date-white' : 'date-blue'"></mat-icon>
          <span>Consultar Escalas</span>
        </a>

        <a
          *ngIf="[role.ApplicationManager] | permission : value.user?.designation"
          mat-list-item
          routerLink="/diagnostics"
          [class.selected-sidenav-item]="isActive('/diagnostics')"
          href="#"
        >
          <mat-icon [svgIcon]="isActive('/diagnostics') ? 'procedure-white' : 'procedure-blue'"></mat-icon>
          <span>Gestão de Diagnósticos</span>
        </a>

        <a
          *ngIf="[role.ApplicationManager] | permission : value.user?.designation"
          mat-list-item
          routerLink="/nursing-diagnostics"
          [class.selected-sidenav-item]="isActive('/nursing-diagnostics')"
          href="#"
        >
          <mat-icon [svgIcon]="isActive('/nursing-diagnostics') ? 'diagnostic-hypothesis-white' : 'diagnostic-hypothesis-blue'"></mat-icon>
          <span>Diagnósticos de Enfermagem</span>
        </a>

        <a
          mat-list-item
          routerLink="/protocols"
          [class.selected-sidenav-item]="isActive('/protocols')"
          href="#"
          *ngIf="[role.ApplicationManager] | permission : value.user?.designation"
        >
          <mat-icon [svgIcon]="isActive('/protocols') ? 'file-white' : 'file-gray'"></mat-icon>
          <span>Protocolos</span>
        </a>

        <a mat-list-item routerLink="/chat" [class.selected-sidenav-item]="isActive('/chat')" href="#">
          <mat-icon [svgIcon]="isActive('/chat') ? 'chat-white' : 'chat-blue'"></mat-icon>
          <span>Chat Geral</span>
        </a>

        <a
          mat-list-item
          routerLink="/users"
          [class.selected-sidenav-item]="isActive('/users')"
          href="#"
          *ngIf="[role.ApplicationManager] | permission : value.user?.designation"
        >
          <mat-icon [svgIcon]="isActive('/users') ? 'user-white' : 'user-gray'"></mat-icon>
          <span>Gestão de Utilizadores</span>
        </a>

        <a
          mat-list-item
          routerLink="/groups"
          [class.selected-sidenav-item]="isActive('/groups')"
          href="#"
          *ngIf="[role.ApplicationManager] | permission : value.user?.designation"
        >
          <mat-icon [svgIcon]="isActive('/groups') ? 'building-white' : 'building-gray'"></mat-icon>
          <span>Unidades de Saúde</span>
        </a>
      </mat-nav-list>

      <mat-nav-list>
        <a mat-list-item (click)="logout()">
          <mat-icon svgIcon="logout-blue"></mat-icon>
          <span>Terminar sessão</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav #drawer [opened]="value.notificationOpen || value.messagesOpen" position="end" mode="over">
      <ng-container
        *ngIf="{
          items:
            value.notifications
            | notifications
              : (value.notificationOpen ? [notificationType.Evacuation, notificationType.EvacuationChat] : [notificationType.Chat])
        } as notification"
      >
        <section class="header background-primary">
          <h1>{{ value.messagesOpen ? 'Mensagens' : 'Notificações' }}</h1>
          <button mat-icon-button (click)="closeNotifications(value.notificationOpen)">
            <mat-icon>close</mat-icon>
          </button>
        </section>
        <mat-list *ngIf="notification.items?.length; else emptyNotification">
          <mat-list-item *ngFor="let notification of notification.items">
            <div class="notification" [class.pointer]="true">
              <mat-card-header>
                <section>
                  <span>{{ notification.title }}</span>
                  <span>{{ notification.date.toDate() | date : 'short' }}</span>
                </section>
              </mat-card-header>
              <mat-card-subtitle>
                {{ notification.body }}
              </mat-card-subtitle>
              <mat-divider></mat-divider>
            </div>
          </mat-list-item>
        </mat-list>
        <ng-template #emptyNotification>
          <div class="empty">
            <mat-icon [svgIcon]="value.messagesOpen ? 'chat-green' : 'notification-gray'"></mat-icon>
            <span>{{ value.messagesOpen ? 'Não existem mensagens' : 'Não existem alertas' }}</span>
          </div>
        </ng-template>
      </ng-container>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar>
        <mat-toolbar-row>
          <div>
            <!--  <button color="primary" mat-icon-button (click)="toggleMenu(value.menuOpen)">
              <mat-icon>menu</mat-icon>
            </button> -->
          </div>
          <div>
            <button class="notification" mat-icon-button (click)="openNotifications()">
              <mat-icon svgIcon="notification-green"></mat-icon>
              <span *ngIf="(value.badgeNotifications || 0) > 0" matBadge="&#8288;" matBadgeColor="warn" matBadgeSize="small"></span>
            </button>

            <button mat-icon-button (click)="openMessages()">
              <mat-icon svgIcon="chat-green"></mat-icon>

              <span *ngIf="(value.badgeMessages || 0) > 0" matBadge="&#8288;" matBadgeColor="warn" matBadgeSize="small"></span>
            </button>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <main>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<mat-card
  class="notification-card"
  [@notificationState]="notificationState$ | async"
  *ngIf="{ notification: notification$ | async } as value"
>
  <mat-card-header>
    <div mat-card-avatar><img class="border-primary" [src]="value.notification?.image || 'assets/images/avatar.png'" /></div>
    <mat-card-title>
      <span class="color-gray500">{{ value.notification?.title }}</span>
    </mat-card-title>
    <mat-card-subtitle>
      <span>{{ value.notification?.body }}</span>
    </mat-card-subtitle>
    <button mat-icon-button (click)="closeNotification()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
</mat-card>
