import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Evacuation, Infection, Injury, NewsScale, Note, OriginEvaluation } from '../models';

@Injectable()
export class OriginEvaluationService {
  readonly patientApi = '/api/patient';
  readonly api = '/api/patient/origin-evaluation';

  constructor(private httpClient: HttpClient) {}

  mapBody(body: OriginEvaluation): unknown {
    const infecao = body.infecao ? (body.infecao as Infection[])?.map(({ key }) => key) : null;
    const cinematicaLesao = body.cinematicaLesao ? (body.cinematicaLesao as Injury[])?.map(({ key }) => key) : null;

    return { ...body, infecao, cinematicaLesao };
  }

  getOriginEvaluation(evacuationUuid: string): Observable<OriginEvaluation> {
    return this.httpClient.get<OriginEvaluation>(`${this.api}/by-evacuation/${evacuationUuid}`);
  }

  updateOriginEvaluation(evaluationId: string, body: OriginEvaluation): Observable<OriginEvaluation> {
    return this.httpClient.put<OriginEvaluation>(`${this.api}/${evaluationId}`, this.mapBody(body));
  }

  createOriginEvaluation(body: OriginEvaluation): Observable<OriginEvaluation> {
    return this.httpClient.post<OriginEvaluation>(`${this.api}`, this.mapBody(body));
  }

  createNews(body: NewsScale[]): Observable<Evacuation> {
    return this.httpClient.post<Evacuation>(`${this.api}/news`, body);
  }

  createNotes(body: Note[]): Observable<Evacuation> {
    return this.httpClient.post<Evacuation>(`${this.api}/notes`, body);
  }

  deleteNote(uuid: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.patientApi}/notas/${uuid}`);
  }
}
