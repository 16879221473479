import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Role } from '../models';

@Injectable({ providedIn: 'root' })
export class RoleService {
  readonly api = `${environment.serverUrl}/portal`;

  constructor(private httpClient: HttpClient) {}

  getRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`${this.api}/roles`);
  }
}
