export interface Paged<T> {
  content?: T[];
  last?: boolean;
  totalElements?: number;
  number?: number;
  loading?: boolean;
  size?: number;
  search?: string;
}

export const PAGE = 0;
export const PAGE_SIZE = 20;

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}
