import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Token } from '@app/shared/models';

import { AuthService } from '@app/shared/services';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token: Token | null = this.authService.getToken();

    if (
      !(
        req.url.endsWith('/login') ||
        req.url.endsWith('/password/forgot') ||
        req.url.includes('/password/token/') ||
        req.url.startsWith('./assets')
      ) &&
      !token
    ) {
      return EMPTY;
    }

    const tokenizeReq =
      token && !req.url.endsWith('refresh-token')
        ? req.clone({
            setHeaders: {
              Authorization: `Bearer ${token.access_token}`
            }
          })
        : req;
    return next.handle(tokenizeReq);
  }
}
