import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paged, Protocol, ProtocolsFilter, QueryParams } from '../models';
import { Observable } from 'rxjs';
import { defaultNumber, defaultString, defaultStringArray } from '../utils';
import { evolve, isNil, reject } from 'ramda';

@Injectable()
export class ProtocolService {
  private readonly api = '/api/patient/protocolos';

  constructor(private httpClient: HttpClient) {}

  getAll({ page, size, search }: QueryParams<ProtocolsFilter> = {}): Observable<Paged<Protocol>> {
    const filterDefaults = {
      page: defaultNumber,
      size: defaultNumber,
      search: defaultString,
      sort: defaultStringArray
    };

    const params = reject(isNil, evolve(filterDefaults)({ page, size, search, mobile: false }));

    return this.httpClient.get<Paged<Protocol>>(this.api, { params });
  }

  createProtocol(file: File, description: string): Observable<Protocol> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('description', description);

    return this.httpClient.post<Protocol>(this.api, formData);
  }

  updateStatus(uuid: string, isActive: boolean): Observable<Protocol> {
    return this.httpClient.put<Protocol>(`${this.api}/${uuid}`, undefined, { params: { isActive } });
  }

  deleteProtocol(uuid: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.api}/${uuid}`);
  }

  getProtocol(id: string): Observable<Blob> {
    return this.httpClient.get<Blob>(`${this.api}/${id}/view-file`, {
      params: { protocolosUuid: id },
      responseType: 'blob' as 'json'
    });
  }
}
