import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { evolve, isNil, omit, reject } from 'ramda';
import { Observable, filter, first, map, mergeMap, take } from 'rxjs';
import { Paged, QueryParams, User, UserFilter } from '../models';
import { defaultNumber, defaultString, defaultStringArray } from '../utils';

@Injectable()
export class UserService {
  readonly api = '/api/auth-user/users';

  constructor(private afs: AngularFirestore, private httpClient: HttpClient, private afStorage: AngularFireStorage) {}

  getUsersFromFirebase(currentUser: string, ids?: string[]): Observable<User[]> {
    return this.afs
      .collection<User>('users', ids ? (ref) => ref.where('id', 'in', ids) : (ref) => ref.where('id', '!=', currentUser))
      .snapshotChanges()
      .pipe(
        take(1),
        map((docs) =>
          docs.map((doc) => ({
            id: doc.payload.doc.id,
            ...doc.payload.doc.data()
          }))
        )
      );
  }

  getUsers({ size, page, search, designation }: QueryParams<UserFilter> = {}): Observable<Paged<User>> {
    const filterDefaults = {
      size: defaultNumber,
      page: defaultNumber,
      name: defaultString,
      designation: defaultStringArray
    };

    const params = reject(
      isNil,
      evolve(filterDefaults)({
        size,
        page,
        designation,
        name: search
      })
    );

    return this.httpClient.get<Paged<User>>(this.api, { params });
  }

  getUser(uuid: string): Observable<User> {
    return this.httpClient.get<User>(`${this.api}/${uuid}`);
  }

  create(user: User): Observable<User> {
    return this.httpClient.post<User>(this.api, {
      ...omit(['uuid'], user)
    });
  }

  update(user: User): Observable<User> {
    return this.httpClient.put<User>(`${this.api}/${user.uuid}`, {
      ...omit(['password', 'uuid'], user)
    });
  }

  enable(uuid: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.api}/${uuid}/enable`, {});
  }

  disable(uuid: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.api}/${uuid}/disable`, {});
  }

  async saveTokenDatabase(userId: string, token: string): Promise<void> {
    const ref = this.afs.collection('fcmTokens').doc(userId);

    try {
      const docSnapshot = await ref.get().pipe(first()).toPromise();

      if (docSnapshot?.exists) {
        const existingData = docSnapshot.data() as { tokens: string[] };
        const existingTokens = existingData.tokens || [];

        if (!existingTokens.includes(token)) {
          return ref.update({ tokens: [...existingTokens, token] });
        }
      } else {
        return ref.set({ tokens: [token] }, { merge: true });
      }
    } catch (error) {
      console.error('Error saving token to database: ', error);
      throw error;
    }
  }

  async removeFcmToken(userId: string, token: string): Promise<void> {
    const ref = this.afs.collection('fcmTokens').doc(userId);

    try {
      const docSnapshot = await ref.get().pipe(first()).toPromise();

      if (docSnapshot?.exists) {
        const existingData = docSnapshot.data() as { tokens: string[] };
        const tokens = (existingData.tokens || []).filter((item) => item != token);

        return ref.update({ tokens });
      }
    } catch (error) {
      console.error('Error saving token to database: ', error);
      throw error;
    }
  }

  saveUserDatabase(user: User): Promise<void> {
    return this.afs
      .collection('users')
      .doc(user.uuid)
      .set(omit(['uuid'], { ...user, id: user.uuid }))
      .catch((error) => console.log(error));
  }

  addPicture(id: string, file: File): Observable<string> {
    const ref = this.afStorage.ref(id);
    const task = ref.put(file);
    return task.snapshotChanges().pipe(
      filter((value) => value?.state === 'success'),
      take(1),
      mergeMap(() => ref.getDownloadURL())
    );
  }
}
