import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordMatchValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.get('newPassword') as FormControl;
    const confirmation = control.get('confirmation') as FormControl;

    if (newPassword?.value !== confirmation?.value) {
      confirmation.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      confirmation.setErrors(null);
      return null;
    }
  };
}
