<main>
  <form [formGroup]="form" (ngSubmit)="submit(form.value, form.valid, token)" *ngIf="{ loading: loading$ | async } as value">
    <header>
      <h2>Redefinição de palavra-passe</h2>
      <p>Por favor redefina a sua nova palavra-passe.</p>
    </header>
    <mat-form-field appearance="outline">
      <input type="password" matInput formControlName="newPassword" placeholder="Nova palavra-passe" />
      <mat-error *ngIf="form.get('newPassword')?.hasError('minlength')">Deve conter no mínimo 8 caracteres.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input type="password" matInput formControlName="confirmation" placeholder="Repetir palavra-passe" />
      <mat-error *ngIf="form.get('confirmation')?.hasError">As palavras-passe não coincidem.</mat-error>
    </mat-form-field>

    <button [disabled]="form.pristine" mat-raised-button color="primary" [disabled]="value.loading">
      <ng-container *ngIf="!value.loading; else loading">Enviar</ng-container>
      <ng-template #loading>
        <mat-icon><mat-spinner color="accent" diameter="20"></mat-spinner></mat-icon>
      </ng-template>
    </button>

    <a routerLink="/..">INICIAR SESSÃO</a>
  </form>
</main>
