import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

@Injectable()
export class MessagingService {
  constructor(private afMessaging: AngularFireMessaging) {}

  requestPermission(): Observable<String | null> {
    return this.afMessaging.tokenChanges;
  }

  receiveMessage(): Observable<firebase.messaging.MessagePayload> {
    return this.afMessaging.messages;
  }
}
