import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClinicalEvolution } from '../models';

@Injectable()
export class ClinicalEvolutionService {
  private readonly api = '/api/patient/clinical-evolution';

  constructor(private httpClient: HttpClient) {}

  get(evacuationId: string): Observable<ClinicalEvolution> {
    return this.httpClient.get<ClinicalEvolution>(`${this.api}/${evacuationId}`);
  }
}
