import { Notification } from '@app/shared/models';
import { createReducer, on } from '@ngrx/store';
import * as MainActions from './main.actions';

export const mainFeatureKey = 'main';

export interface State {
  notifications: Notification[];
  notificationsLoading: boolean;
  badgeMessages?: number;
  badgesNotifications?: number;
  error?: string;
}

export const initialState: State = {
  notificationsLoading: false,
  notifications: []
};

export const reducer = createReducer(
  initialState,

  on(MainActions.loadNotifications, (state) => ({ ...state, notificationsLoading: true })),
  on(MainActions.loadNotificationsSuccess, (state, { notifications }) => ({ ...state, notifications, notificationsLoading: false })),
  on(MainActions.loadNotificationsFailure, (state, { error }) => ({ ...state, error, notificationsLoading: false })),

  on(MainActions.loadBadgeMessagesSuccess, (state, { count }) => ({ ...state, badgeMessages: count })),
  on(MainActions.loadBadgeMessagesFailure, (state, { error }) => ({ ...state, error })),

  on(MainActions.loadBadgesNotificationsSuccess, (state, { count }) => ({ ...state, badgesNotifications: count })),
  on(MainActions.loadBadgesNotificationsFailure, (state, { error }) => ({ ...state, error }))
);
