import * as ProfileActions from '@app/features/profile/store/profile.actions';
import { User } from '@app/shared/models';
import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  loading: boolean;
  user?: User;
  error?: string;
  recovery?: {
    recovered?: boolean;
    loading?: boolean;
  };
  changePassword?: {
    changed?: boolean;
    loading?: boolean;
  };
}

export const initialState: State = {
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(AuthActions.login, (state) => ({ ...state, loading: true })),
  on(AuthActions.loginSuccess, (state, { user }) => ({ ...state, user, loading: false })),
  on(AuthActions.loginFailure, (state, { error }) => ({ ...state, error, loading: false })),

  on(AuthActions.loadUserInformationSuccess, (state, { user }) => ({ ...state, user })),
  on(AuthActions.loadUserInformationFailure, (state, { error }) => ({ ...state, error })),

  on(AuthActions.setUser, (state, { user }) => ({ ...state, user })),

  on(AuthActions.logoutSuccess, () => initialState),
  on(AuthActions.logoutFailure, () => initialState),

  on(AuthActions.setGuest, (state) => ({ ...state, user: undefined })),

  on(ProfileActions.updatePictureStringSuccess, (state, { user }) => ({ ...state, user: { ...state.user!, photo: user.photo } })),
  on(ProfileActions.updateProfileSuccess, (state, { user }) => ({
    ...state,
    user: { ...state.user!, specialty: user.specialty, name: user.name }
  })),

  on(AuthActions.recoveryPassword, (state) => ({ ...state, recovery: { ...state.recovery, recovered: false, loading: true } })),
  on(AuthActions.recoveryPasswordSuccess, (state) => ({ ...state, recovery: { ...state.recovery, recovered: true, loading: false } })),
  on(AuthActions.recoveryPasswordFailure, (state, { error }) => ({ ...state, recovery: { ...state.recovery, loading: false }, error })),

  on(AuthActions.cleanRecoveryPassword, (state) => ({ ...state, recovery: undefined })),

  on(AuthActions.changePassword, (state) => ({ ...state, changePassword: { ...state.changePassword, changed: false, loading: true } })),
  on(AuthActions.changePasswordSuccess, (state) => ({
    ...state,
    changePassword: { ...state.changePassword, changed: true, loading: false }
  })),
  on(AuthActions.changePasswordFailure, (state, { error }) => ({
    ...state,
    changePassword: { ...state.changePassword, loading: false },
    error
  })),

  on(AuthActions.cleanChangePassword, (state) => ({ ...state, changePassword: undefined }))
);
