import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { evolve, isNil, reject } from 'ramda';
import { Observable, retry } from 'rxjs';
import { DiagnosticHypothesis, DiagnosticHypothesisFilter, Paged, QueryParams } from '../models';
import { defaultNumber, defaultString, defaultStringArray } from '../utils';

@Injectable()
export class DiagnosticHypothesisService {
  readonly api = '/api/patient/manage/diagnostic-hypothesis';

  constructor(private httpClient: HttpClient) {}

  getAll({ page, size, search }: QueryParams<DiagnosticHypothesisFilter> = {}): Observable<Paged<DiagnosticHypothesis>> {
    const filterDefaults = {
      page: defaultNumber,
      size: defaultNumber,
      search: defaultString,
      sort: defaultStringArray
    };

    const params = reject(
      isNil,
      evolve(filterDefaults)({
        page,
        size,
        search,
        sort: ['diagnosis']
      })
    );

    return this.httpClient.get<Paged<DiagnosticHypothesis>>(this.api, { params });
  }

  createDiagnosis(diagnosis: DiagnosticHypothesis): Observable<DiagnosticHypothesis> {
    return this.httpClient.post<DiagnosticHypothesis>(this.api, diagnosis);
  }

  updateDiagnosis(diagnosis: DiagnosticHypothesis): Observable<DiagnosticHypothesis> {
    return this.httpClient.put<DiagnosticHypothesis>(`${this.api}/${diagnosis.uuid}`, diagnosis);
  }

  updateStatus(id: string, isAvailable: boolean): Observable<DiagnosticHypothesis> {
    return this.httpClient.put<DiagnosticHypothesis>(`${this.api}/${id}`, { isAvailable });
  }

  delete(id: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.api}/${id}`);
  }
}
