export enum Role {
  ClinicalDirection = 'Direcao_Clinica',
  NurseUEA = 'Enfermeiro_UEA',
  ApplicationManager = 'Gestor_de_Aplicacao',
  DoctorDecision = 'Medico_Decisor',
  DoctorDestination = 'Medico_Destino',
  DoctorUEA = 'Medico_UEA',
  DoctorOrigin = 'Medico_Origem',
  AdministrativeUEA = 'Administrativo_UEA'
}
