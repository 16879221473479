import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClinicalEvaluationValue, Obstetric } from '../models';

@Injectable()
export class ObstetricService {
  readonly api = '/api/patient/obstetricia';
  private readonly tabObstetricAPI = 'api/patient/monitoring/tab-obstetricia/evacuations';

  constructor(private httpClient: HttpClient) {}

  getObstetric(evacuationUuid: string): Observable<Obstetric> {
    return this.httpClient.get<Obstetric>(`${this.api}/${evacuationUuid}`);
  }

  updateObstetric(evacuationId: string, body: Obstetric): Observable<Obstetric> {
    return this.httpClient.put<Obstetric>(`${this.api}/${evacuationId}`, body);
  }

  createObstetric(body: Obstetric): Observable<Obstetric> {
    return this.httpClient.post<Obstetric>(`${this.api}`, body);
  }

  getObstetricValues(evacuationId: string, assessmentType: string): Observable<ClinicalEvaluationValue[]> {
    return this.httpClient.get<ClinicalEvaluationValue[]>(`${this.tabObstetricAPI}/${evacuationId}`, {
      params: {
        assessmentType
      }
    });
  }
}
