import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { AngularFireModule } from '@angular/fire/compat';
import { URL as DATABASE_URL } from '@angular/fire/compat/database';
import { SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KeycloakAngularModule } from 'keycloak-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { AuthModule } from './features/auth/auth.module';
import { MessagingService } from './shared/services';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule,
    MatDialogModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),

    AngularFireMessagingModule,
    KeycloakAngularModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({ maxAge: 40, logOnly: environment.production }),
    EffectsModule.forRoot([])
  ],
  providers: [
    MessagingService,
    {
      provide: DATABASE_URL,
      useValue: environment.emulator ? `http://localhost:9000?ns=${environment.firebaseConfig.projectId}` : undefined
    },
    { provide: FIRESTORE_SETTINGS, useValue: environment.emulator ? { host: 'localhost:8090', ssl: false } : {} },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'pt-PT' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
