import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../enums';

@Pipe({
  name: 'permission'
})
export class PermissionPipe implements PipeTransform {
  transform(roles?: Role[] | undefined, permissions?: Role[] | undefined): boolean {
    return !!roles && !!roles.some((role) => permissions?.includes(role));
  }
}
