<main>
  <header>
    <img width="300" src="assets/images/logo.png" />
  </header>
  <section>
    <router-outlet></router-outlet>
  </section>
  <footer>
    <img src="assets/images/logo-acores.png" />
    <p>Esta é uma presença online oficial do Governo dos Açores Hospital de Santo Espírito da Ilha Terceira, EPER</p>
  </footer>
</main>
