import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Confirmation {
  title?: string;
  text?: string;
  cancelButton?: boolean;
  showIcon?: boolean;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  title: string;
  text: string;
  cancelButton?: boolean;
  showIcon?: boolean;

  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: Confirmation) {
    this.title = data?.title || 'Confirmação';
    this.text = data?.text || 'Tem a certeza';
    this.cancelButton = data?.cancelButton == undefined ? true : data?.cancelButton;
    this.showIcon = data?.showIcon || false;
  }
}
