import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, map, mergeMap, Observable, of, take } from 'rxjs';
import { Patient } from '../models';

@Injectable({ providedIn: 'root' })
export class PatientService {
  readonly collection = 'patients';

  constructor(private afs: AngularFirestore) {}

  getById(id: string): Observable<Patient> {
    return this.afs
      .collection<any>(this.collection)
      .doc(id)
      .snapshotChanges()
      .pipe(
        take(1),
        map((doc) => {
          return { ...doc.payload.data(), id: doc.payload.id };
        })
      );
  }

  create(patient: Patient): Observable<Patient> {
    const ref = this.afs.collection(this.collection);

    return of(patient); /* from(
      ref
        .add({
          ...patient,
          birthdayDate: patient.birthdayDate
            ? patient.birthdayDate instanceof DateTime
              ? patient.birthdayDate
              : DateTime.fromISO(patient.birthdayDate as string)
            : ''
        })
        .then((value) => value.id)
    ).pipe(map((id) => ({ ...patient, id }))); */
  }

  update(patient: Patient): Observable<Patient> {
    const ref = this.afs.collection(this.collection).doc(patient.id.toString());

    return from(ref.update(patient)).pipe(mergeMap(() => this.getById(patient.id!.toString())));
  }

  delete(id: string): Observable<boolean> {
    return of(true);
  }
}
