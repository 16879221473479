import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(fromAuth.authFeatureKey);

export const selectCurrentUser = createSelector(selectAuthState, (state: fromAuth.State) => state.user);

export const selectLoading = createSelector(selectAuthState, (state: fromAuth.State) => state.loading);

export const selectPasswordRecovered = createSelector(selectAuthState, (state: fromAuth.State) => state.recovery?.recovered);
export const selectPasswordRecoveryLoading = createSelector(selectAuthState, (state: fromAuth.State) => state.recovery?.loading);

export const selectPasswordChanged = createSelector(selectAuthState, (state: fromAuth.State) => state.changePassword?.changed);
export const selectPasswordChangeLoading = createSelector(selectAuthState, (state: fromAuth.State) => state.changePassword?.loading);
