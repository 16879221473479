import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { uuid } from 'fast-check';
import { evolve, isNil, reject } from 'ramda';
import { Observable } from 'rxjs';
import { Paged, Procedure, ProcedureFilter, QueryParams } from '../models';
import { defaultNumber, defaultString, defaultStringArray } from '../utils';

@Injectable()
export class ProcedureService {
  private readonly api = '/api/patient/manage/type-procedures';

  constructor(private httpClient: HttpClient) {}

  getAll({ page, size, search }: QueryParams<ProcedureFilter> = {}): Observable<Paged<Procedure>> {
    const filterDefaults = {
      page: defaultNumber,
      size: defaultNumber,
      search: defaultString,
      sort: defaultStringArray
    };

    const params = reject(isNil, evolve(filterDefaults)({ page, size, search, sort: ['type'] }));

    return this.httpClient.get<Paged<Procedure>>(this.api, { params });
  }

  createProcedure(procedure: Procedure): Observable<Procedure> {
    return this.httpClient.post<Procedure>(this.api, procedure);
  }

  updateProcedure(procedure: Procedure): Observable<Procedure> {
    return this.httpClient.put<Procedure>(`${this.api}/${procedure.uuid}`, procedure);
  }

  deleteProcedure(uid: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.api}/${uuid}`);
  }

  updateStatus(id: string, isAvailable: boolean): Observable<Procedure> {
    return this.httpClient.put<Procedure>(`${this.api}/${id}`, { isAvailable });
  }
}
