export enum EvacuationType {
  Obstetric = 'OBSTETRICO',
  Pediatric = 'PEDIATRICO',
  Doctor = 'MEDICA',
  Trauma = 'TRAUMA'
}

export enum TypeOfPregnancy {
  Unique = 'UNICA',
  Twin = 'GEMELAR'
}

export enum PhaseLaborAndDelivery {
  Latent = 'LATENTE',
  Active = 'ATIVA'
}

export enum MembraneRupture {
  Yes = 'SIM',
  No = 'NAO',
  Unknown = 'DESCONHECIDA'
}

export enum EvacuationStatus {
  Draft = 'RASCUNHO',
  OnApproval = 'EM_APROVACAO',
  Approved = 'APROVADA',
  Confirmed = 'CONFIRMADA',
  Reproved = 'REPROVADA',
  Suspended = 'SUSPENSA',
  Running = 'EM_EXECUCAO',
  Done = 'CONCLUIDA'
}

export enum Permeable {
  Permeable = 'Permeavel'
}

export enum AtRisk {
  Secretions = 'Secreções',
  Vomiting = 'vómitos',
  Blood = 'sangue',
  Prostheses = 'próteses',
  Teeth = 'dentes',
  Others = 'outros'
}

export enum CervicalColor {
  Present = 'Presente',
  NotApplied = 'Não Aplicável'
}

export enum Amplitude {
  Normal = 'Normal',
  Superficial = 'Superficial',
  Deep = 'Profunda'
}

export enum LungAuscultation {
  MVWithoutAdventitiousNoise = 'MV sem Ruídos adventícios',
  WithAdventitiousNoiseStasisFever = 'Com Ruídos adventícios (Fervores de estase)',
  WithAdventitiousNoiseCrackles = 'Com Ruídos adventícios (Crepitações)',
  WithAdventitiousNoiseWheezing = 'Com Ruídos adventícios (Sibilos)',
  WithAdventitiousNoiseSnores = 'Com Ruídos adventícios (Roncos)',
  WithAdventitiousNoisePleuralFriction = 'Com Ruídos adventícios (Atrito pleural)',
  WithAdventitiousNoiseOthers = 'Com Ruídos adventícios (Outros)',
  Symmetrical = 'Simétricos',
  Asymmetrical = 'Assimétricos',
  LocationInTheInspirationRespiratoryCycle = 'Localização no ciclo respiratório (Inspiração)',
  LocationInTheExpirationRespiratoryCycle = 'Localização no ciclo respiratório (Expiração)',
  OriginLocationOnTheTorso = 'Local de origem no torso'
}

export enum Palpation {
  NoChange = 'Sem alterações',
  WithChanges = 'Com alterações'
}

export enum Percussion {
  Dullness = 'Maçicez',
  Hyperresonance = 'Hiperressonância'
}

export enum TPC {
  TPC1 = '<2s',
  TPC2 = '>=2s'
}

export enum Pulse {
  NormalAmplitude = 'Amplitude Normal',
  SuperficialAmplitude = 'Amplitude Superficial',
  DeepAmplitude = 'Amplitude Profunda'
}

export enum Color {
  ColorLess = 'Incolor',
  Orange = 'Laranja',
  Red = 'Vermelho',
  Greenish = 'Esverdeada',
  Dark = 'Escura',
  Amber = 'Âmbar',
  StrawYellow = 'Amarelo Palha'
}

export enum Smell {
  Intense = 'Intenso',
  Ketone = 'Cetónico',
  Sweet = 'Doce',
  SuiGeneris = 'Sui Generis'
}

export enum Transparency {
  WithSediment = 'Com Sedimento',
  Blurred = 'Turva',
  CloudyWithSediment = 'Turva com Sedimento',
  Clear = 'Límpida'
}

export enum Drainage {
  Feed = 'Alimentar',
  Biliary = 'Biliar',
  Hematic = 'Hemático',
  Fecal = 'Fecal',
  Aqueous = 'Aquoso'
}

export enum GeometricForm {
  Round = 'Redonda',
  Oval = 'Oval',
  Irregular = 'Irregular'
}

export enum Nystagmus {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal'
}

export enum StateConsciousness {
  Alert = 'Alerta',
  Verbal = 'Verbal',
  Pain = 'Dor',
  WithoutResponse = 'Sem resposta'
}
