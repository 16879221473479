import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMain from './main.reducer';

export const selectMainState = createFeatureSelector<fromMain.State>(fromMain.mainFeatureKey);

export const notifications = createSelector(selectMainState, (state: fromMain.State) => state.notifications);

export const selectNotificationsLoading = createSelector(selectMainState, (state: fromMain.State) => state.notificationsLoading);

export const selectNotifications = createSelector(selectMainState, (state: fromMain.State) => state.notifications);

export const selectBadgeMessages = createSelector(selectMainState, (state: fromMain.State) => state.badgeMessages);

export const selectBadgeNotifications = createSelector(selectMainState, (state: fromMain.State) => state.badgesNotifications);
