import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { default as config } from '@package';
import { Observable } from 'rxjs';
import { login } from '../../store/auth.actions';
import { selectLoading } from '../../store/auth.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  form: FormGroup;
  appVersion: string;

  loading$: Observable<boolean>;

  constructor(private fb: FormBuilder, public store: Store) {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.loading$ = this.store.pipe(select(selectLoading));

    this.appVersion = config.version;
  }

  login(user: { username: string; password: string }, valid: boolean): void {
    if (valid) {
      this.store.dispatch(login(user));
    }
  }
}
