import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NursingDiagnostic, NursingDiagnosticFilter, Paged, QueryParams } from '../models';
import { Observable } from 'rxjs';
import { defaultNumber, defaultString, defaultStringArray } from '../utils';
import { evolve, isNil, reject } from 'ramda';

@Injectable()
export class NursingDiagnosticService {
  readonly api = '/api/patient/manage/nursing-diagnostics';
  constructor(private httpClient: HttpClient) {}

  getAll({ page, size, search }: QueryParams<NursingDiagnosticFilter> = {}): Observable<Paged<NursingDiagnostic>> {
    const filterDefaults = {
      page: defaultNumber,
      size: defaultNumber,
      search: defaultString,
      sort: defaultStringArray
    };

    const params = reject(
      isNil,
      evolve(filterDefaults)({
        page,
        size,
        search,
        sort: ['category']
      })
    );

    return this.httpClient.get<Paged<NursingDiagnostic>>(this.api, { params });
  }

  create(nursingDiagnostic: NursingDiagnostic): Observable<NursingDiagnostic> {
    return this.httpClient.post<NursingDiagnostic>(this.api, nursingDiagnostic);
  }

  update(nursingDiagnostic: NursingDiagnostic): Observable<NursingDiagnostic> {
    return this.httpClient.put<NursingDiagnostic>(`${this.api}/${nursingDiagnostic.uuid}`, nursingDiagnostic);
  }

  delete(uuid: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.api}/${uuid}`);
  }
}
