import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Apgar, ChildBirthRecord, ObstetricPatient } from '../models';

@Injectable()
export class ObstetricPatientService {
  private readonly api = '/api/patient/obstetric-patients';

  constructor(private httpClient: HttpClient) {}

  get(motherId: string): Observable<ObstetricPatient[]> {
    return this.httpClient.get<ObstetricPatient[]>(`${this.api}/by-mother/${motherId}`);
  }

  getChildBirth(evacuationId: string): Observable<ChildBirthRecord> {
    return this.httpClient.get<ChildBirthRecord>(`${this.api}/${evacuationId}/parto/records`);
  }

  getApgar(patientId: string, evacuationId: string): Observable<Apgar[]> {
    return this.httpClient
      .get<{ apgarRepresentations: Apgar[] }>(`${this.api}/${evacuationId}/apgar-records/by-patient/${patientId}`)
      .pipe(map(({ apgarRepresentations }) => apgarRepresentations));
  }
}
