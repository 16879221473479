<main>
  <form [formGroup]="form" (ngSubmit)="login(form.value, form.valid)" *ngIf="{ loading: loading$ | async } as value">
    <h1>
      <span>MED</span>
      <span>EVAC</span>
    </h1>
    <mat-form-field appearance="outline">
      <input matInput formControlName="username" placeholder="ID Profissional" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input type="password" matInput formControlName="password" placeholder="Palavra-passe" />
    </mat-form-field>

    <button [disabled]="form.pristine" mat-raised-button color="primary" [disabled]="value.loading">
      <ng-container *ngIf="!value.loading; else loading">Entrar</ng-container>
      <ng-template #loading>
        <mat-icon><mat-spinner color="accent" diameter="20"></mat-spinner></mat-icon>
      </ng-template>
    </button>

    <div class="version-container">
      <p>Versão atualizada {{ appVersion }}</p>
      <a routerLink="/credentials/recovery-password">esqueceu-se da palavra-passe? Clique aqui</a>
    </div>
  </form>
</main>
