import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from '@app/shared/components/confirmation-modal/confirmation-modal.component';
import { Role } from '@app/shared/enums';
import { Notification, NotificationType, User } from '@app/shared/models';
import { MessagingService } from '@app/shared/services';
import { Store, select } from '@ngrx/store';
import firebase from 'firebase/compat/app';
import { append } from 'ramda';
import { BehaviorSubject, Observable, Subscription, delay, filter, interval, of, take, withLatestFrom } from 'rxjs';
import { logout } from '../auth/store/auth.actions';
import { selectCurrentUser } from '../auth/store/auth.selectors';
import {
  loadBadgeMessages,
  loadBadgesNotifications,
  loadNotifications,
  markAllMessagesRead,
  markAllNotificationsRead,
  saveTokenDatabase
} from './store/main.actions';
import { selectBadgeMessages, selectBadgeNotifications, selectNotifications } from './store/main.selectors';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('notificationState', [
      state(
        'closed',
        style({
          opacity: 0,
          visibility: 'hidden'
        })
      ),
      state(
        'open',
        style({
          opacity: 1
        })
      ),
      transition('open <=> closed', [animate('1s ease')])
    ])
  ]
})
export class MainComponent implements OnInit, OnDestroy {
  notificationState$: BehaviorSubject<string>;
  notification$: BehaviorSubject<firebase.messaging.NotificationPayload | undefined>;
  notificationSub?: Subscription;

  notificationsOpen$: BehaviorSubject<boolean>;
  notifications$: Observable<Notification[]>;

  messagesOpen$: BehaviorSubject<boolean>;

  badgeMessages$: Observable<number | undefined>;
  badgeNotifications$: Observable<number | undefined>;

  user$: Observable<User | undefined>;

  subscriptions: Subscription[];

  menuOpen$: BehaviorSubject<boolean>;

  constructor(public store: Store, private messagingService: MessagingService, private router: Router, private dialog: MatDialog) {
    this.subscriptions = [];
    this.notificationState$ = new BehaviorSubject('closed');
    this.notification$ = new BehaviorSubject<firebase.messaging.NotificationPayload | undefined>(undefined);
    this.notificationsOpen$ = new BehaviorSubject(false);
    this.messagesOpen$ = new BehaviorSubject(false);

    this.notifications$ = this.store.pipe(select(selectNotifications));
    this.user$ = this.store.pipe(select(selectCurrentUser));
    this.badgeMessages$ = this.store.pipe(select(selectBadgeMessages));
    this.badgeNotifications$ = this.store.pipe(select(selectBadgeNotifications));

    this.menuOpen$ = new BehaviorSubject(true);
  }

  get role(): typeof Role {
    return Role;
  }

  get notificationType(): typeof NotificationType {
    return NotificationType;
  }

  ngOnInit(): void {
    this.store.dispatch(loadNotifications());
    this.store.dispatch(loadBadgeMessages());
    this.store.dispatch(loadBadgesNotifications());

    const requestPermissionSub = this.messagingService
      .requestPermission()
      .pipe(
        filter((token) => !!token),
        delay(2000)
      )
      .subscribe({
        next: (token) => {
          this.store.dispatch(saveTokenDatabase({ token: token as string }));
        },
        error: (error) => {
          console.log('Error: ', error);
        }
      });
    this.subscriptions = append(requestPermissionSub, this.subscriptions);

    const receivedMessageSub = this.messagingService
      .receiveMessage()
      .pipe(
        withLatestFrom(this.notificationState$),
        filter(([payload]) => !!payload.notification)
      )
      .subscribe(async ([payload, state]) => {
        if (state === 'open') {
          this.notificationState$.next('closed');
          this.notificationSub?.unsubscribe();
          of(true)
            .pipe(take(1), delay(1000))
            .subscribe(() => this.openNotification(payload.notification!));
        } else {
          this.openNotification(payload.notification!);
        }
      });

    this.subscriptions = append(receivedMessageSub, this.subscriptions);
  }

  openNotification(notification: firebase.messaging.NotificationPayload): void {
    this.notification$.next(notification);
    this.notificationState$.next('open');
    this.notificationSub = interval(6000)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationState$.next('closed');
      });
  }

  closeNotification(): void {
    this.notificationState$.next('closed');
    this.notificationSub?.unsubscribe();
  }

  openMessages(): void {
    this.messagesOpen$.next(true);
  }

  openNotifications(): void {
    this.notificationsOpen$.next(true);
  }

  closeNotifications(notificationOpen?: boolean | null): void {
    if (notificationOpen) {
      this.notificationsOpen$.next(false);
      this.store.dispatch(markAllNotificationsRead());
    } else {
      this.messagesOpen$.next(false);
      this.store.dispatch(markAllMessagesRead());
    }
  }

  logout(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '25.438rem',
      autoFocus: false,
      data: {
        text: 'Tem certeza que pretender sair?'
      }
    });

    const sub = dialogRef
      .afterClosed()
      .pipe(filter((value) => !!value))
      .subscribe(() => this.store.dispatch(logout()));

    this.subscriptions = append(sub, this.subscriptions);
  }

  isActive(url: string): boolean {
    if (this.router.url.startsWith('/evacuation')) {
      if (url === '/evacuation') {
        return !this.router.url.split('/').find((value) => value === 'new');
      }
      return this.router.url.startsWith(url);
    }
    return this.router.url.startsWith(url);
  }

  toggleMenu(value: boolean | null): void {
    this.menuOpen$.next(!value);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
