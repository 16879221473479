import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from '@app/shared/components/confirmation-modal/confirmation-modal.component';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, filter, of } from 'rxjs';
import { cleanRecoveryPassword, recoveryPassword } from '../../store/auth.actions';
import { selectPasswordRecovered, selectPasswordRecoveryLoading } from '../../store/auth.selectors';
import { append } from 'ramda';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecoveryPasswordComponent implements OnDestroy {
  loading$: Observable<boolean | undefined>;

  form: FormGroup;

  subscriptions: Subscription[];

  constructor(private fb: FormBuilder, private dialog: MatDialog, private router: Router, private store: Store) {
    this.loading$ = this.store.pipe(select(selectPasswordRecoveryLoading));

    this.form = this.fb.group({
      userEmail: ['', Validators.required]
    });

    this.subscriptions = [];

    const sub = this.store
      .pipe(
        select(selectPasswordRecovered),
        filter((value) => !!value)
      )
      .subscribe(() => this.confirmation());

    this.subscriptions = append(sub, this.subscriptions);
  }

  submit({ userEmail }: { userEmail: string }, valid: boolean): void {
    if (valid) {
      this.store.dispatch(recoveryPassword({ userEmail }));
    }
  }

  confirmation(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '25.438rem',
      autoFocus: false,
      data: {
        title: 'Recuperação de palavra-passe',
        text: 'Caso a sua conta exista no Medevac, será enviado um email para proceder à alteração da palavra-passe.',
        cancelButton: false,
        showIcon: true
      }
    });

    const sub = dialogRef
      .afterClosed()
      .pipe(filter((value) => !!value))
      .subscribe(() => {
        this.router.navigate(['../']);
      });

    this.subscriptions = append(sub, this.subscriptions);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((sub) => sub.unsubscribe());
    this.store.dispatch(cleanRecoveryPassword());
  }
}
